import React from 'react';
import '../App.css';
import affiantofficer from '../images/affiant-officer.svg'
import tblflag from '../images/blueline-canada-flag.svg';
import copyright from '../images/copyright-text.svg';
import lock from '../images/lock.svg';
import faker from 'faker';

import Navigation from './Navigation';

const App = () => {
    return (
        <div className="container-wrap">
            <div className="background-wrap margin-bottom-sm bottom-padding">
                <div className="ui container side-padding">
                    <div className="affiantNav">
                        <Navigation />
                    </div>
                    <div className="ui stackable two column grid">
                        <div className="eight wide column middle aligned">
                            <img className="affiant-officer" src={affiantofficer} alt="Affiant Officer"></img>
                        </div>
                        <div className="eight wide column middle aligned">
                            <h1>Hello Affiant.</h1>
                            <p>{faker.lorem.sentences(2)}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ui container top-padding side-padding margin-bottom">
                <div className="ui stackable two column grid">
                    <div className="eight wide column">
                        <h1>Supercharge your productivity with features including:</h1>
                        <p>{faker.lorem.paragraph()}</p>
                        <p>
                            <ul>
                                <li>{faker.lorem.sentence()}</li>
                                <li>{faker.lorem.sentence()}</li>
                                <li>{faker.lorem.sentence()}</li>
                            </ul>
                        </p>
                        <p>{faker.lorem.paragraph()}</p>
                        <h1>Security centric design:</h1>
                        <p>{faker.lorem.paragraph()}</p>
                        <p><img className="lock" src={lock} alt="Security"></img></p>
                        <p>{faker.lorem.paragraphs()}</p>
                    </div>
                    <div className="eight wide column">
                        <h1>Developed by Law Enforcement, for Law Enforcement</h1>
                        <p>{faker.lorem.paragraph()}</p>
                        <p><img className="tbl-flag" src={tblflag} alt="Thin Blue Line"></img></p>
                        <p>{faker.lorem.paragraph()}</p>
                    </div>
                </div>

            </div>
            <div className="background-wrap-footer">
                <div className="ui container top-padding side-padding">
                    <img className="copyright" src={copyright} alt="copy"/>
                </div>
            </div>
        </div>
    );
};

export default App;