import React from 'react';
import affiantlogo from '../images/affiantlogo.svg';

const Navigation = () => {
    return (
        <div className="ui secondary  menu">
            <div><img className="affiant-logo" src={affiantlogo} alt="affiant logo" /></div>
            <div className="right menu">
                <a href="/#" className="active item">
                    Coming Soon
                </a>
            </div>
        </div>
    );
};

export default Navigation;
